import { useState } from "react"

import { Pager } from "src/components/Pager/Pager"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { useFetchMembers } from "src/data/organizations/queries/memberQueries"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { InternalLink } from "src/ui/Link/InternalLink"

const LIMIT = 15

export function ParadiseOrganizationMembers({ orgId }: { orgId: string }) {
  const { navigate } = useRouter()

  const [offset, setOffset] = useState(0)

  const fetchMembers = useFetchMembers({
    orgId,
    filter: {
      offset,
      limit: LIMIT,
    },
  })

  const headers = [
    <div key={"user-id"}>User id</div>,
    <div key={"name"}>Name</div>,
    <div key={"member-id"}>Member id</div>,
    <div key={"role-in-organization"}>Role in organization</div>,
  ]

  const rows =
    fetchMembers.data?.members.map((member) => (
      <>
        {member.user_id ? (
          <InternalLink
            to={Routes.ParadiseUser.location(member.user_id)}
            onClick={(e) => e.stopPropagation()}
          >
            {member.user_id}
          </InternalLink>
        ) : (
          "-"
        )}
        <div>{member.name}</div>
        <div>{member.member_id}</div>
        <div>{member.role}</div>
      </>
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection title="Members" />
      <ParadiseTable
        header={headers}
        rows={rows}
        onRowClick={(index) => {
          const memberUserId = fetchMembers.data?.members[index].user_id

          if (memberUserId) {
            navigate(Routes.ParadiseUser.location(memberUserId))
          }
        }}
      />
      <Pager
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        totalCount={fetchMembers.data?.paging.total_count}
      />
    </div>
  )
}
