import { useState } from "react"
import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import { ParadiseCreateClientDialog } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseCreateClientDialog"
import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { ParadiseUserApiClientSecret } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserApiClients/ParadiseUserApiClientSecret"
import { IdWrapper } from "src/components/Paradise/sharedStyles"
import { useFetchApiClients } from "src/data/apiClients/apiClientQueries"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { mColors } from "src/ui/colors"
import { CopyText } from "src/ui/CopyText/CopyText"
import InfoIcon from "src/ui/icons/info.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

const LIMIT = 15

export function ParadiseOrganizationAPIClients({ orgId }: { orgId: string }) {
  const { navigate } = useRouter()

  const [offset, setOffset] = useState(0)
  const [showCreateClientDialog, setShowCreateClientDialog] = useState(false)

  const fetchedUserApiClients = useFetchApiClients({
    owner_type: OwnerType.ORGANIZATION,
    owner_id: orgId,
    limit: LIMIT,
    offset,
    options: {
      keepPreviousData: true,
    },
  })

  const headers = [
    <div key={"name"}>Name</div>,
    <div key={"created-at"}>Created at</div>,
    <div key={"client-id"}>Client id</div>,
    <div key={"client-secret"}>Client secret</div>,
  ]

  const rows =
    fetchedUserApiClients.data?.clients.map((client) => (
      <>
        <div>
          <IdWrapper>
            <div>{client.name}</div>
            {client.deleted && (
              <Tooltip title="Deleted">
                <InfoIcon width={12} fill={mColors.systemError} />
              </Tooltip>
            )}
          </IdWrapper>
        </div>
        <div>{client.created_at}</div>
        <div>
          <ClientBox>
            <InternalLink
              to={Routes.ParadiseClient.location(client.client_id)}
              onClick={(e) => e.stopPropagation()}
            >
              {client.client_id}
            </InternalLink>
            <CopyText
              value={client.client_id}
              copiedText="Copied!"
              width={20}
            />
          </ClientBox>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <ParadiseUserApiClientSecret clientId={client.client_id} />
        </div>
      </>
    )) ?? []

  return (
    <div>
      <ParadiseDetailsSection
        title="API Clients"
        buttonLabel="Create client"
        onButtonClick={() => setShowCreateClientDialog(true)}
      />
      <ParadiseTable
        header={headers}
        rows={rows}
        onRowClick={(index) => {
          if (fetchedUserApiClients.data?.clients) {
            navigate(
              Routes.ParadiseClient.location(
                fetchedUserApiClients.data.clients[index].client_id
              )
            )
          }
        }}
        error={{
          hasError: fetchedUserApiClients.isError,
          title: fetchedUserApiClients.error?.message,
        }}
      />
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedUserApiClients.data?.paging.total_count}
      />
      <ParadiseCreateClientDialog
        open={showCreateClientDialog}
        onClose={() => setShowCreateClientDialog(false)}
        fixedOwnerType={OwnerType.ORGANIZATION}
        fixedOwnerId={orgId}
      />
    </div>
  )
}

const ClientBox = styled.div`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
`
